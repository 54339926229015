/*
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
*/

:root {
  --primary-color: #fbc212;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Open Sans", sans-serif;
}

html {
  font-size: 16px;
  scroll-behavior: smooth;
}

a:visited {
  color: rgb(0, 0, 238);
}

a:hover {
  cursor: pointer;
}

h2 {
  font-size: 1.5rem;
}

p {
  font-size: 1rem;
}

table {
  width: 60%;
}

button {
  color: #fff;
  font-weight: bold;
  font-size: 1rem;
  background-color: var(--primary-color);
  border: none;
  margin: 1rem auto;
  padding: 0.75rem 1rem;
  text-decoration: none;
}

button:hover {
  cursor: pointer;
  background-color: #d5a60e;
}


.button {
  color: #fff;
  font-weight: bold;
  background-color: var(--primary-color);
  border: none;
  margin: 1rem auto;
  padding: 0.75rem 2rem;
  text-decoration: none;
}

.button:visited {
  color: #fff;
}

.button:hover {
  cursor: pointer;
  background-color: #d5a60e;
}

/* Form styles */
form {
  margin-bottom: 2rem;
}

input[type=text] {
  display: block;
  width: 100%;
  padding: 0.5rem 1rem;
  margin: 0.5rem 0;
  border: 1px solid rgb(226, 226, 226);
  border-radius: 6px;
  outline: none;
}

input[type=text]:focus {
  border: 1px solid rgb(138, 138, 138);
}

input[type=submit] {
  color: #fff;
  font-weight: bold;
  background-color: var(--primary-color);
  border: none;
  width: 100%;
  margin: 1rem auto;
  padding: 0.75rem 0.6rem;
}

input[type=submit]:hover {
  cursor: pointer;
  background-color: #d5a60e;
}

textarea {
  display: block;
  width: 100%;
  padding: 0.5rem 1rem;
  margin: 0.5rem 0;
  border: 1px solid rgb(226, 226, 226);
  border-radius: 6px;
  outline: none;
}

select {
  display: block;
  width: 100%;
  padding: 0.5rem 1rem;
  margin: 0.5rem 0;
  border: 1px solid rgb(226, 226, 226);
  border-radius: 6px;
  outline: none;
}

/* Menu */

.menu-1 {
  background-color: var(--primary-color);
  color: #fff;
  padding-top: 1vh;
  padding-bottom: 1vh;
  padding-left: 10%;
  padding-right: 10%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  height: 4rem;
}

.menu-1-social-logo-container {
  width: 5%;
  display: flex;
  justify-content: space-around;
  align-items: center;
  justify-items: center;
}

.menu-1-social-logo {
  width: 2rem;
  min-width: 20px;
}

.menu-2-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  text-align: center;
  padding: 1.5% 10%;
  height: 5rem;
  position: sticky;
  top: 0px;
  background-color: #fff;
  z-index: 10;
  box-shadow: rgba(0, 0, 0, 0.12) 0 0.125rem 0.25rem 0;
}

.menu-2-logo {
  width: 5vw;
  min-width: 120px;
  height: 3.5rem;
}

.menu-2 {
  width: 50%;
  display: flex;
  justify-content: space-between;
  position: relative;
}

.menu-2-link {
  display: inline-block;
  color: black;
  text-decoration: none;
  font-weight: bold;
  margin: 0.5rem;
}

.menu-2 a.active {
  color: var(--primary-color);
}

.menu-2-link:visited {
  color: black;
}


.menu2__dropdown__beurs {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  position: absolute;
  top: 1.9rem;
  background-color: white;
  padding-left: 1rem;
  padding-right: 2rem;
  padding-bottom: 1rem;
}

.menu2__dropdown__beurs a {
  display: block;
  color: black;
  text-decoration: none;
  font-weight: bold;
  margin-top: 2rem;
}

.menu2__dropdown__beurs:visited {
  color: black;
}

.menu2__dropdown {
  position: relative;
  display: inline-block;
}

.menu-2-hamburger {
  display: none;
}

@media only screen and (max-width: 700px) {
  .menu-2 {
    display: none;
  }

  .menu-2-container {
    padding: 1.5% 4%;
  }

  .menu-2-hamburger {
    display: block;
    height: 100%;
  }

  .menu2__dropdown__hamburger {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    position: absolute;
    top: 5rem;
    right: 0.1rem;
    background-color: white;
    padding-left: 1rem;
    padding-right: 2rem;
    padding-bottom: 1rem;
  }

  .menu2__dropdown__hamburger a {
    display: block;
    color: black;
    text-decoration: none;
    font-weight: bold;
    margin-top: 1rem;
  }

  .menu2__dropdown__hamburger:visited {
    color: black;
  }

  .menu2__dropdown__hamburger__sub {
    padding-left: 2rem;
  }
}

/* Global grid settings */
.container__page {
  display: grid;
  grid-template-areas:
    "hero hero hero"
    "left content right";
  grid-template-columns: 1fr 2fr 1fr;
  gap: 40px;
}

/* CSS Grid + area's */

.container-home {
  display: grid;
  grid-template-areas:
    "hero hero hero hero"
    "left header_info1 header_info1 right"
    "left info1 image1 right"
    "left home__header2 home__header2 right"
    "left home__time home__time right"
    "counter counter counter counter"
    "newsleft newsletter newsletter newsright";
  grid-template-columns: 1fr 2fr 2fr 1fr;
  gap: 40px;
}


.hero {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  grid-area: hero;
  justify-self: center;
  align-self: center;
  width: 100%;
  height: 30rem;
  background-image: url("../src/assets/website-hero.webp");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  color: #fff;
  box-shadow: rgba(0, 0, 0, 0.12) 0 0.25rem 0.25rem 0;
}

.hero h1 {
  font-size: 3rem;
  width: 50%;
  text-align: center;
  margin-bottom: 30px;
}

.hero h2 {
  margin-bottom: 20px;
  width: 80%;
  text-align: center;
}

.hero h1,
.hero h2 {
  text-shadow: 3px 3px 2px black;
}

.header_info1 {
  grid-area: header_info1;
  justify-self: start;
}

.info1 {
  grid-area: info1;
}

.image1 {
  grid-area: image1;
  overflow: hidden;
}

.image1 img {
  max-width: 100%;
  min-width: 300px;
  height: auto;
  display: block;
}

.home__header2 {
  grid-area: home__header2;
  margin-top: 2rem;
}

.home__time {
  grid-area: home__time;
  margin-bottom: 2rem;
}

.newsletter {
  grid-area: newsletter;
  width: 50%;
  justify-self: center;
  align-items: center;
}

.counter {
  display: flex;
  justify-content: center;
  text-align: center;
  grid-area: counter;
  color: white;
  background-color: var(--primary-color);
}

.counter div {
  margin: 2% 5% 2% 5%;
}

.counter h2 {
  font-size: 3rem;
}

/* Media queries for home page */

@media only screen and (max-width: 480px) {
  .container-home {
    grid-template-areas:
      "hero hero hero"
      "left header_info1 right"
      "left info1 right"
      "left home__header2 right"
      "left home__time right"
      "counter counter counter"
      "newsleft newsletter newsright";
    grid-template-columns: 2% 1fr 2%;
  }

  .newsletter {
    width: 100%;
  }

  table {
    width: 100%;
  }

}


/* Newsletter form */
.newsletter h2 {
  margin-bottom: 1rem;
}

/* Beurs pagina */
.container-beurs {
  display: grid;
  grid-template-areas:
    "hero hero hero"
    "left header_info1 right"
    "left info_beurs right"
    "left buttonMap right"
    "mapleft map mapright";
  grid-template-columns: 1fr 6fr 1fr;
  gap: 40px;
}

.map {
  width: 100%;
  grid-area: map;
}

.info_beurs {
  grid-area: info_beurs;
}

.buttonMap {
  grid-area: buttonMap;
  display: flex;
}

.buttonMap button {
  width: 40%;
}

@media only screen and (max-width: 480px) {
  .container-beurs {
    grid-template-columns: 2% 1fr 2%;
  }
}

/* Programma pagina */
.container-programma {
  display: grid;
  width: 100%;
  grid-template-areas:
    "hero hero hero hero hero"
    "left header_info1 header_info1 header_info1 right"
    "left filter filter filter right"
    "left cardcontainer cardcontainer cardcontainer right";
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
  gap: 20px;
  grid-auto-flow: row;
  justify-items: center;
}

.header_info1 p {
  margin-top: 1rem;
}

.filter {
  grid-area: filter;
  justify-self: start;
}

.filter button {
  margin-right: 20px;
}


.cointainer-card {
  display: grid;
  grid-area: cardcontainer;
  grid-template-columns: 1fr 1fr 1fr;
  grid-template-rows: 1fr;
  grid-auto-rows: 1fr;
  gap: 0.75rem;
}

.card {
  background: #263064;
  border-radius: 0.5rem;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 2px 6px;
  display: grid;
  grid-template-rows: 1fr 1fr;
  width: 22rem;
  height: 42rem;
  overflow: hidden;
}

.card:hover {
  box-shadow: rgba(0, 0, 0, 0.1) 0px 6px 16px;
}

.card__preview {
  height: 22rem;
  width: 100%;
  position: relative;
  overflow: hidden;
}

.card__preview img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.card__date {
  background-color: white;
  color: #000;
  z-index: 1;
  position: absolute;
  bottom: 0.5rem;
  padding: 0.25rem 0.5rem;
  border-radius: 0.5rem;
  left: 1.25rem;
  font-weight: bold;
  min-width: auto;
  max-width: auto;
}

.card__content {
  padding: 1.25rem;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  color: white;
}

.card__content h3 {
  font-size: 1.1rem;
}

.card__location {
  font-style: italic;
  margin-top: 0.5rem;
}

.card__speaker {
  margin-top: 0.5rem;
  overflow: hidden;
}

.card__company {
  margin-top: 0.5rem;
  margin-bottom: 1rem;
}

.card__bottom {
  margin-top: auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.card__link {
  color: #fff;
  font-weight: bold;
  box-sizing: border-box;
  background-color: var(--primary-color);
  text-decoration: none;
  padding: 10px 20px;

}

.card__link:hover {
  cursor: pointer;
  background-color: #d5a60e;
}

.card__link:visited {
  color: #fff;
}

@media only screen and (max-width: 820px) {
  .container-programma {
    grid-template-areas:
      "hero hero hero"
      "left header_info1 right"
      "left filter right"
      "left cardcontainer right";
    grid-template-columns: 2% 1fr 2%;
  }
}

@media only screen and (max-width: 1200px) {
  .cointainer-card {
    grid-template-columns: 1fr 1fr;
  }
}

@media only screen and (max-width: 620px) {
  .cointainer-card {
    grid-template-columns: 1fr;
  }
}

/* PresentationSingle page */
.container-presentationSingle {
  display: grid;
  grid-template-areas:
    "hero hero hero hero"
    "singleLeft singleLeft singleRight singleRight"
    "left single_content single_content right";
  grid-template-columns: 1fr 2fr 2fr 1fr;
}

.container-single-content {
  grid-area: single_content;
  margin-top: 2rem;
  width: 80%;
  justify-self: center;
}

.singleRight {
  grid-area: singleRight;
  margin: 0;
  text-align: center;
}

.singleLeft {
  grid-area: singleLeft;
  margin: 0;
  text-align: center;
}

.presentationSingle__beschrijving {
  white-space: pre-line;
}

.spreker {
  margin-top: 2rem;
}

.presentationSingle__date {
  margin-top: 2rem;
}

@media only screen and (max-width: 1024px) {
  .container-presentationSingle .hero h1 {
    font-size: 2rem;
  }

  .container-presentationSingle .hero h2 {
    display: none;
  }
}

@media only screen and (max-width: 355px) {

}

/* By products */

.separator {
  background-color: #000;
  width: 50px;
  height: 2px;
  margin: 2vh 0;
}

/* Partners */

.partners {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 4rem;
}

.partners-logo {
  display: flex;
  overflow: hidden;
  width: 70%;
  justify-content: space-evenly;
  align-items: center;
  margin-top: 2rem;
  margin-bottom: 4rem;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}

.partners-logo img {
  border: 1px solid rgb(182, 182, 182);
  border-radius: 10px;
  transition: transform 0.5s;
  cursor: pointer;
  height: auto;
  display: block;
  max-width: 100%;
}

.partners-logo img:hover {
  transform: scale(1.05);
}

@media only screen and (max-width: 480px) {
  .partners-logo {
    flex-direction: column;
    width: 50%;
  }

  .partners-logo img {
    width: 100%;
    margin-bottom: 1rem;
  }
}

/* deelname pagina */
.container-deelname {
  display: grid;
  grid-template-areas: "hero"
    "frame";
  justify-items: center;
}

iframe {
  grid-area: frame;
  width: 80%;
  height: 750px;
  margin-top: 50px;
}

/* contact pagina */
.container-contact {
  display: grid;
  grid-template-areas: "hero"
    "titel"
    "contact";
  justify-items: center;
}

.titel {
  grid-area: titel;
  width: 50%;
  margin-top: 3rem;
  margin-bottom: 3rem;
}

.contact {
  grid-area: contact;
  width: 50%;
}

/* Beurs Handboek */
.handboek__container {
  display: grid;
  grid-template-areas: "hero"
    "content";
  justify-items: center;
}

.handboek__content {
  grid-area: content;
  width: 50%;
  margin-top: 1rem;
}

.handboek__content div {
  margin-top: 1rem;
  background-color: rgb(225, 225, 225);
  padding: 1rem;
  border: 1px solid gray;
}

.handboek__content div h4 {
  margin-top: 1rem;
}

.handboek__content div ul {
  margin-left: 3rem;
}

/* Prijzen pagina */
.prijzen__container {
  display: grid;
  grid-template-areas: "hero hero hero"
    "left prijzen__header right"
    "left content right";
  grid-template-columns: 1fr 2fr 1fr;
  justify-items: center;
}

.prijzen__header {
  grid-area: prijzen__header;
  justify-self: start;
  margin-top: 2rem;
}

.prijzen__header p {
  margin-top: 1rem;
}

.prijzen__content-container {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 40rem;
  grid-auto-rows: 40rem;
  grid-area: content;
  gap: 2rem;
  margin-top: 2rem;
  justify-content: center;
}

.prijzen__content-container ul {
  margin-top: 1rem;
  padding-left: 2rem;
}

.prijzen__beursplaats {
  background: #263064;
  color: white;
  display: flex;
  flex-direction: column;
  width: 25rem;
}

.prijzen__beursplaats img {
  object-fit: cover;
}

.prijzen__beursplaats-content {
  padding: 1.5rem;
}

@media only screen and (max-width: 480px) {
  .prijzen__container {
    grid-template-columns: 2% 1fr 2%;
  }

  .prijzen__content-container {
    grid-template-columns: 1fr;
  }

}

/* Winkel */
.winkel__container {
  display: grid;
  grid-template-areas: "hero hero hero"
    "left winkel__header right"
    "left winkel__content right"
    "left winkel__cart right";
  grid-template-columns: 1fr 1fr 1fr;
}

.winkel__header {
  grid-area: winkel__header;
  margin-top: 2rem;
  margin-bottom: 2rem;
}

.winkel__header h2 {
  margin-bottom: 1rem;
}

.winkel__header p {
  margin-bottom: 1rem;
}

.winkel__btn__cart {
  grid-area: right;
  margin-top: 10rem;
  display: flex;
  justify-content: center;
}

.winkel__btn__cart button {
  position: sticky;
  top: 10rem;
}

.winkel__content-container {
  grid-area: winkel__content;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 1rem;
  justify-items: center;
}

.winkel__cart {
  grid-area: winkel__cart;
  margin-top: 2rem;
}

.winkel__cart__order {
  margin-top: 2rem;
}

.winkel__cart__form {
  margin-top: 2rem;
}

#cart__submit {
  width: 20%;
}

#bestellijst {
  scroll-margin-top: 6rem;
}

.winkelCard {
  background: #263064;
  border-radius: 0.5rem;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 2px 6px;
  display: flex;
  flex-direction: column;
  width: 22rem;
  height: 35rem;
  overflow: hidden;
}

.winkelCard:hover {
  box-shadow: rgba(0, 0, 0, 0.1) 0px 6px 16px;
}

.winkelCard__preview {
  height: 20rem;
  width: 100%;
  position: relative;
  overflow: hidden;
}

.winkelCard__preview img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.winkelCard__prijs {
  background-color: white;
  color: #000;
  z-index: 1;
  position: absolute;
  bottom: 0.5rem;
  padding: 0.25rem 0.5rem;
  border-radius: 0.5rem;
  left: 1.25rem;
  font-weight: bold;
}

.winkelCard__content {
  padding: 1.25rem;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  color: white;
}

.winkelCard__bottom {
  margin-top: auto;
  display: flex;
}

.winkelCard__bottom__quantity {
  display: flex;
  align-items: center;
}

.winkelCard__bottom__quantity p{
  margin-right: 1rem;
  margin-left: 1rem;
  background-color: white;
  color: #000;
  padding: 0.75rem 1rem;
  margin: 0;
}

.winkel__cart__product {
  list-style: none;
}

.winkel__cart__quantity {
  display: inline;
  margin-right: 1rem;
}

.winkel__cart__quantity span{
  padding: 0.75rem 1rem;
}

.winkel__cart__succes {
  background-color: rgb(15, 240, 15);
  border: 1px solid gray;
  padding: 1rem;
}

.winkel__cart__empty {
  background-color: rgb(255, 55, 20);
  color: #fff;
  border: 1px solid gray;
  padding: 1rem;
}


/* Overlay voor catering formulier */
.winkel__overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.7); /* Donkere achtergrond */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000; /* Zorgt ervoor dat de overlay bovenop andere elementen ligt */
}

.winkel__overlay-content {
  background-color: white;
  padding: 20px;
  position: relative;
  z-index: 1001;
  width: 70%;
  height: 80%;
  overflow: auto;
}

.winkel__overlay-close-button {
  position: absolute;
  top: 10px;
  right: 10px;
  border: none;
  cursor: pointer;
}

.winkel__overlay__product {
  display: flex;
  align-items: center;
  margin-bottom: 1rem;
}

.winkel__overlay__product button {
  margin: 0;
}

.winkel__overlay__product p {
  width: 40%
}

/* Media queries winkel pagina */
@media only screen and (max-width: 1300px) {
  .winkel__content-container {
    grid-template-columns: 1fr 1fr;
  }
}

@media only screen and (max-width: 1024px) {
  #orderForm_btn {
    display: none;
  }

  .winkel__content-container {
    grid-template-columns: 1fr 1fr;
  }
}

@media only screen and (max-width: 550px) {
  .winkel__content-container {
    grid-template-columns: 1fr;
  }

  .winkel__container {
    grid-template-columns: 5% 1fr 5%;
  }
}

/* Reserveren pagina */
.reserveren__container {
  display: grid;
  grid-template-areas: "hero hero hero"
    "left reserveren__header right"
    "left reserveren__form right";
  grid-template-columns: 1fr 1fr 1fr;
}

.reserveren__header {
  grid-area: reserveren__header;
  margin-top: 2rem;
}

.reserveren__form {
  margin-top: 2rem;
  grid-area: reserveren__form;
}

@media only screen and (max-width: 480px) {
  .reserveren__container {
    grid-template-columns: 5% 1fr 5%;
  }
}

/* Streamtheater pagina */
.streamtheater__container {
  display: grid;
  grid-template-areas: "hero hero hero"
    "left reserveren__header right"
    "left reserveren__form right";
  grid-template-columns: 1fr 1fr 1fr;
}

.streamtheater__header {
  grid-area: reserveren__header;
  margin-top: 2rem;
}

.streamtheater__form {
  margin-top: 2rem;
  grid-area: reserveren__form;
}

.streamtheater__form p{
  margin-bottom: 2rem;
}

@media only screen and (max-width: 480px) {
  .streamtheater__container {
    grid-template-columns: 5% 1fr 5%;
  }
}

/* Live pagina */
.live__container {
  display: grid;
  grid-template-areas: "hero hero hero"
    "left content right"
    "left evaluatie right";
  grid-template-columns: 1fr 70% 1fr;
}

.live__content {
  grid-area: content;
  margin-top: 2rem;
  margin-bottom: 4rem;
}

.live__evaluatie {
  grid-area: evaluatie;
  margin-top: 2rem;
  margin-bottom: 4rem;
}

.live__evaluatie  h2{
  margin-bottom: 2rem;
}

.radioButton{
  margin-right: 2rem;
  margin-bottom: 2rem;
  display: inline-block;
}

.live__evaluatie label{
  margin-left: 1rem;
}

.responsive-video {
  position: relative;
  width: 100%;
  padding-top: 56.25%; /* 16:9 verhouding */
}

.responsive-video iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 0;
}

/* Footer */

footer {
  background-color: #282828;
  color: #fff;
  padding: 5% 5%;
  display: flex;
  justify-content: center;
}

.footer-container {
  display: flex;
  justify-content: space-evenly;
  width: 60%;
}

.footer-container-sub {
  width: 50%;
}

.footer-social-logo-container {
  margin: 20% 0;
}

.footer-social-logo {
  width: 1.5vw;
  margin-right: 0.5vw;
}

/* Media queries */
@media only screen and (max-width: 800px) {
  html {
    font-size: 12px;
  }
}

  @media only screen and (max-width: 480px) {
  .counter {
    flex-direction: column;
  }

  .image1 {
    display: none;
  }
}